export default defineNuxtRouteMiddleware(async () => {
  const { fetchUser, setUser } = useDirectusAuth();
  const user = useDirectusUser();

  if (!user.value) {
    try {
      const user = await fetchUser().catch(() => ref(null));
      setUser(user.value);
    } catch (error) {}
  }

  if (!user.value) {
    return createError({
      statusCode: 401,
      statusMessage: "Unauthorized",
      message: "UNAUTHORIZED",
    });
  }
});
